import { put, call } from "redux-saga/effects";
import baseActions from "reducers/base";
import { isEmpty } from "lodash";

export function* getFormConfig(api: any): any {
  try {
    const response = yield call(api.getFormConfig);
    if (response.ok) {
      const { data } = response;
      yield put(baseActions.getFormConfigSuccess(data));
    } else {
      const { error } = response;
      yield put(baseActions.getFormConfigFailure(error));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* saveForm(api: any, action: any): any {
  const { efnol, isSubmit } = action;
  const reporter_type = efnol?.reporter_type?.value

  const updatedFNOL = {
    ...efnol,
    form_id: window?.location?.origin?.includes("kfbclaims") ? "KFB" : "WTU",
    claimant_name: efnol?._is_claimant
      ? reporter_type === "Insured"
        ? !isEmpty(efnol?.insured_name)
          ? efnol?.insured_name : efnol?.reporter_name
        : efnol?.reporter_name : efnol?.claimant_name,
    claimant_phone: efnol?._is_claimant ? efnol?.reporter_phone : efnol?.claimant_phone,
    claimant_email: efnol?._is_claimant ? efnol?.reporter_email : efnol?.claimant_email,
    incident_datetime: efnol?.incident_datetime?.toString(),
    reporter_type,
    incident_location_state: efnol?.incident_location_state?.value,
    reporter_veh_plate_state: efnol?.reporter_veh_plate_state?.value,
    reporter_dl_state: efnol?.reporter_dl_state?.value,
  }

  try {
    let response;
    if (isSubmit) {
      response = yield call(api.submit, updatedFNOL);
    } else {
      response = yield call(api.saveForm, updatedFNOL);
    }
    if (response.ok) {
      const { data } = response;
      if (isSubmit) {
        yield put(baseActions.setSubmittedId())
      } else {
        yield put(baseActions.saveSuccess(data));
      }
    } else {
      const { error } = response;
      yield put(baseActions.saveFailure(error));
    }
  } catch (error) {
    console.error(error);
  }
}
