import React from "react";
import { Section } from "../../layout";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import {
  StyledFieldWrapper,
  StyledToggleLabel,
} from "../styled-form-components";
import { FieldLabelComponent } from "../field.label";
import { isEmpty } from "lodash";
import { FormContentRenderer } from "../index";
import { FieldTypes } from "../../../@types";

export const SwitchButton = (props: any) => {
  const {
    label,
    getToggleLabels = (value: boolean) => (value ? "Yes" : "No"),
    field,
    onChange,
    hint,
    switchType = "default",
    nonControlledProps,
    width = "100%",
    info,
    disabled = false,
    subQuestions = [],
    autocompleteProps = {},
    initialValues = {},
    required = false,
    formData = {},
    handleUpdateContact,
    handleUpdateEntity,
    defaultValue,
    dropzoneProps = {},
  } = props || {};
  const {
    // getFieldState,
    watch,
    validateOnEvent,
    handleAutosave,
    onFormSubmit,
  } = nonControlledProps;
  const fieldValue = watch(field?.name);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 30,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(14px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "var(--primary1)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(34,31,32,0.18)",
      boxSizing: "border-box",
    },
  }));
  return (
    <StyledFieldWrapper id={field?.name} width={width}>
      <FieldLabelComponent
        label={label}
        hint={hint}
        info={info}
        isRequired={nonControlledProps?.isRequired || required}
      />
      <Section
        style={{
          marginTop: "8px",
          padding: "5px 0",
          // backgroundColor: invalid ? "var(--light-red)" : "white",
        }}
        $flexDirection="row"
        $alignItems="center"
      >
        <StyledToggleLabel
          style={{
            marginRight: "10px",
          }}
        >
          {getToggleLabels && getToggleLabels(false)}
        </StyledToggleLabel>
        <MaterialUISwitch
          checked={fieldValue}
          {...field}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.checked;
            onChange(val);
            if (validateOnEvent) {
              validateOnEvent();
            }
          }}
        />
        <StyledToggleLabel
          style={{
            marginLeft: "10px",
          }}
        >
          {getToggleLabels && getToggleLabels(true)}
        </StyledToggleLabel>
      </Section>
      {!isEmpty(subQuestions) &&
        subQuestions.map((q: any, idx: number) => {
          const { displayIf, mappedField, type, name, fieldType } = q || {};
          const fieldName = name || mappedField;
          const contentType = fieldType || type;
          if (
            (displayIf?.condition === "equal" &&
              field?.value === displayIf?.value) ||
            (displayIf?.condition === "oneOf" &&
              displayIf?.value?.includes(field?.value))
          ) {
            const dv =
              defaultValue ||
              (!isEmpty(formData) && formData?.[fieldName]) ||
              undefined;
            const additionalSubQProps =
              type === FieldTypes.dropzone
                ? {
                    files: formData[fieldName] || [],
                    ...dropzoneProps,
                  }
                : {};
            return (
              <Section
                key={fieldName + idx}
                style={{
                  padding: "0 45px 0 20px",
                  borderLeft: "2px solid var(--background-darker-grey)",
                  marginLeft: 45,
                  marginTop: 10,
                }}
              >
                <FormContentRenderer
                  renderWithoutFormTags={true}
                  formFields={[
                    {
                      name: mappedField || name,
                      fieldType: contentType,
                      defaultValue: dv,
                      ...autocompleteProps,
                      ...q,
                      ...additionalSubQProps,
                      showTotalPowerUnits: q?.addressType?.includes("_garage"),
                      handleUpdateContact,
                    },
                  ]}
                  initialValues={initialValues}
                  autosave={true}
                  onFormSubmit={onFormSubmit}
                  hideFormSubmit={true}
                  handleUpdateEntity={handleUpdateEntity}
                  formData={formData}
                />
              </Section>
            );
          } else {
            return <div key={fieldName}></div>;
          }
        })}
    </StyledFieldWrapper>
  );
};
