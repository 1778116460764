import { SyntheticEvent } from "react";

export type IFieldType =
  | "input"
  | "disabled-input"
  | "input-search"
  | "input-search-textarea"
  | "textarea"
  | "select"
  | "multi-select"
  | "date-select"
  | "date-time-select"
  | "date"
  | "autocomplete"
  | "switch"
  | "toggle"
  | "checkbox"
  | "upload"
  | "phone"
  | "fein"
  | "dropzone"
  | "input-list"
  | "currency"
  | "currency-number"
  | "no-render"
  | "dollar-table"
  | "contact"
  | "grid"
  | "dot"
  | "integer"
  | "percentage"
  | "percentage-table"
  | "address"
  | "entity"
  | "custom-render";

export const FieldTypes = {
  input: "input" as IFieldType,
  disabled_input: "disabled-input" as IFieldType,
  input_search: "input-search" as IFieldType,
  input_search_textarea: "input-search-textarea" as IFieldType,
  textarea: "textarea" as IFieldType,
  select: "select" as IFieldType,
  multi_select: "multi-select" as IFieldType,
  date_select: "date-select" as IFieldType,
  date_time_select: "date-time-select" as IFieldType,
  date: "date" as IFieldType,
  autocomplete: "autocomplete" as IFieldType,
  switch: "switch" as IFieldType,
  toggle: "toggle" as IFieldType,
  checkbox: "checkbox" as IFieldType,
  upload: "upload" as IFieldType,
  phone: "phone" as IFieldType,
  fein: "fein" as IFieldType,
  dropzone: "dropzone" as IFieldType,
  input_list: "input-list" as IFieldType,
  currency: "currency" as IFieldType,
  currency_number: "currency-number" as IFieldType,
  no_render: "no-render" as IFieldType,
  percentage_table: "percentage-table" as IFieldType,
  dollar_table: "dollar-table" as IFieldType,
  custom_render: "custom-render" as IFieldType,
  grid: "grid" as IFieldType,
  contact: "contact" as IFieldType,
  entity: "entity" as IFieldType,
  dot: "dot" as IFieldType,
  integer: "integer" as IFieldType,
  address: "address" as IFieldType,
  percentage: "percentage" as IFieldType,
};

// https://react-hook-form.com/api/useform/register
export interface IFieldRules {
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  pattern?: RegExp;
  validate?: Function | Object;
  valueAsNumber?: boolean;
  valueAsDate?: boolean;
  setValueAs?: <T>(value: any) => T;
  disabled?: boolean;
  onChange?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  value?: unknown;
  shouldUnregister?: boolean;
  deps?: string | string[];
}

export interface IHeader {
  label: string;
  type: string;
  key: string;
  options?: Array<{ label: string; value: string | number | boolean }>;
}

export interface IField {
  name: string;
  label: string;
  hint?: string;
  value?: any;
  fieldType: IFieldType;
  type?: IFieldType;
  onSearchClick?: (...args: any) => void;
  onFieldChange?: (...args: any) => void;
  searchSucceeded?: boolean;
  info?: string;
  loading?: boolean;
  rules?: IFieldRules;
  CustomComponent?: any;
  getCustomComponent?: any;
  getToggleLabels?: (val: boolean) => string;
  customOnRemoveItem?: (val: { label: string; value: string }) => void;
  defaultValue?: any;
  controlledDefaultValue?: boolean;
  width?: string;
  hasInitialValue?: boolean;
  required?: boolean;
  options?: Array<any>;
  placeholder?: string;
  headers?: Array<IHeader>;
  addRowsComponent?: any;
  handleAddRow?: any;
  showDeleteRowBtn?: boolean;
  handleDeleteRow?: any;
  contact_type?: string;
  contactFields?: Array<string>;
  getTemplate?: () => void;
  onFileSelect?: any;
  formData?: any;
  displayIf?: any;
}

export const FieldRulesTypes = {
  required: "required",
  maxLength: "maxLength",
  minLength: "minLength",
  max: "max",
  min: "min",
  pattern: "pattern",
  validate: "validate",
  valueAsNumber: "valueAsNumber",
  valueAsDate: "valueAsDate",
  setValueAs: "setValueAs",
  disabled: "disabled",
  onChange: "onChange",
  onBlur: "onBlur",
  value: "value",
  shouldUnregister: "shouldUnregister",
  deps: "deps",
  type: "type",
};

export interface IContentRenderer {
  formFields: Array<IField>;
  formSchema?: any;
  onFormSubmit?: ((formData: any) => void) | false;
  onFormChange?: ((formData: any) => void) | false;
  onFormValidate?: (formErrors: any) => void;
  initialValues?: any;
  formSubmitLoading?: boolean;
  withAutocomplete?: boolean;
  formContentWrapperStyle?: object;
  $toggleModal?: () => void;
  formSubmitLabel?: string;
  autosave?: boolean;
  hideFormSubmit?: boolean;
  id?: string;
  getCustomButtonRow?: (props: any) => void;
  renderWithoutFormTags?: boolean;
  style?: object;
  formTitle?: string;
  additionalProps?: any;
  dropzoneProps?: any;
  handleUpdateEntity?: any;
  formData?: any;
}

export interface ISelectVal {
  label: string;
  value: string | number | Date;
}
