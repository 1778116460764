import { FieldTypes, IField } from "../../@types";
import * as yup from "yup";

export const withRequiredState = (yupValidator: any, required: boolean) => {
  return required ? yupValidator.required() : yupValidator.nullable();
};

export const getDefaultFormSchema = (fields: Array<IField>) => {
  let schemaObj: any = {};

  fields?.forEach((f: IField) => {
    const { name, fieldType, required = false } = f || {};

    switch (fieldType) {
      case FieldTypes.dot:
        return (schemaObj[name] = withRequiredState(yup.string().test(
              "len",
              "DOT number must be 8 digits or less",
              (val = "") => {
                const len = val?.length || 0
                return len <= 8 && len > 0
              }
            ), required));
      case FieldTypes.textarea:
      case FieldTypes.input:
        return (schemaObj[name] = withRequiredState(yup.string(), required));
      case FieldTypes.fein:
        return (schemaObj[name] = withRequiredState(
          yup
            .string()
            .test(
              "len",
              "FEIN Number must be 9 digits (10 characters including the hyphen).",
              (val = "") => val.length <= 10
            ),
          required
        ));
      case FieldTypes.date_select:
        // TODO: Add regex for date formatting or date requirements
        return (schemaObj[name] = withRequiredState(yup.string(), required));
      // TODO: case contacts
      // case "contact":
      //   return (schemaObj[name] = yup.object();
      case FieldTypes.percentage:
      case FieldTypes.integer:
        return (schemaObj[name] = withRequiredState(yup.number(), required));
      // TODO: case GRID
      // case "grid":
      //   return schemaObj[name] = yup.object()
      // TODO: case percentage-table, dollar-table, address, select, multi-select, grid
      case FieldTypes.select:
        return (schemaObj[name] = withRequiredState(yup.object(), required));
      case FieldTypes.multi_select:
        return (schemaObj[name] = withRequiredState(yup.object(), required));
      case FieldTypes.switch:
      case FieldTypes.toggle:
        return (schemaObj[name] = withRequiredState(yup.boolean(), required));
      default:
        return;
    }
  });

  return yup.object(schemaObj);
};
